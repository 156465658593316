<template>
    <div id="tagsList" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="bleTags"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="tag_tagsListSubTitle"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper" v-if="!isEmptyList">
                                    <button @click="openImportTagsModal" id="tags_importTagsFromXLSButton" class="btn btn-success btn-bold btn-sm btn-icon-h kt-margin-l-10">
                                        <i class="fa fa-file-excel kt-margin-t-1 kt-margin-r-5"></i>
                                        {{ $t("common_importFromXLSFile") }}
                                    </button>
                                    <span class="kt-margin-l-10">{{ $t("common_or") }}</span>
                                    <router-link v-if="!isEmptyList" :to="{ name: 'newTag' }" id="tags_createNewTagButton" class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10" data-cy="createNewTagBtn">
                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                        {{ $t("tag_createNewTag") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile ">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M12,13.8453624 L12,18.758963 L15.3125397,16.8266482 L12,13.8453624 Z M10,12.0453624 L6.33103527,8.74329415 C5.92052525,8.37383513 5.88724683,7.74154529 6.25670585,7.33103527 C6.62616487,6.92052525 7.25845471,6.88724683 7.66896473,7.25670585 L17.6689647,16.2567059 C18.172608,16.7099848 18.0891527,17.5223646 17.503871,17.8637789 L11.503871,21.3637789 C10.8372144,21.7526619 10,21.2717908 10,20.5 L10,12.0453624 Z" id="Path-20-Copy" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                            <path d="M12,10.4375595 L15.3984747,7.54885603 L12,5 L12,10.4375595 Z M10,3 C10,2.17595468 10.9407637,1.70557281 11.6,2.2 L17.6,6.7 C18.1131546,7.08486597 18.1363893,7.84650959 17.6476484,8.26193932 L7.64764842,16.7619393 C7.22684095,17.1196257 6.59574703,17.0684559 6.23806068,16.6476484 C5.88037434,16.226841 5.93154411,15.595747 6.35235158,15.2380607 L10,12.1375595 L10,3 Z" id="Path-20" fill="#000000" fill-rule="nonzero" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("tag_tagsList") }}
                                                </h3>
                                            </div>
                                            <div v-if="!isEmptyList" class="kt-portlet__head-toolbar">
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <div class="row align-items-center">
                                                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                                                <div class="kt-input-icon kt-input-icon--left">
                                                                    <input type="text" :placeholder="$t('common_searchBySerial')" @keyup="onSearch" v-model="searchedValue" id="tags_searchInput" class="searchInputDatatable form-control form-control-sm width270" />
                                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                                        <span><i class="la la-search"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button @click="exportTagsModal" id="tags_exportTagsFromXLSButton" class="exportXLSXButton btn btn-sm btn-outline-success kt-margin-l-10">
                                                    <i class="fa fa-file-excel kt-margin-r-5"></i>
                                                    {{ $t("common_exportFromXLSFile") }}
                                                </button>
                                            </div>
                                        </div>
                                        <!--begin emptyList component-->
                                        <app-emptylist targetObjectName="TAG" mainTextKey="tag_notFound" subTextKey="tag_notFoundSubLabel" imgName="empty_tag.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                        <!--end emptyList component-->
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="tags_datatable"></div>
                                            <div v-if="isBatteryStatusHelpDisplayed" class="kt-widget19__text" style="color: rgb(118, 121, 140); margin-bottom: 17px; margin-left: 21px;">
                                                <sup>*</sup> {{ $t("tag_EstimatedBatteryHelpText") }}
                                            </div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>
                    <!-- begin:: modal -->
                    <app-importtagsfromXLSmodal ref="importTagsModal"></app-importtagsfromXLSmodal>
                    <!-- end:: modal -->
                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "../site/widgets/sitetitle.vue";
import ImportTagsModal from "../modals/importtagsmodal.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            siteTagsTable: null,
            isEmptyList: false,
            searchedValue: "",
            isBatteryStatusHelpDisplayed: false
        };
    },
    created: function() {
        console.log("Component(Tags)::created() - called");
        if (this.createAnotherTag) {
            this.$router.push({ name: "newTag" });
        } else {
            // Load site tags from backend and store the result in sites store.
            this.getSiteTags(this.$route.params.siteId);
        }
    },
    mounted: function() {
        console.log("Component(Tags)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    beforeDestroy: function() {
        console.log("Component(Tags)::beforeDestroy() - called");
        this.searchedValue = "";
        // Reset component data
        if (this.siteTagsTable) {
            this.siteTagsTable.destroy();
            this.siteTagsTable = null;
        }
        // Reset store
        this.resetTagsState();
    },
    destroyed: function() {
        console.log("Component(Tags)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteTags: function(tags) {
            console.log("Component(SiteTags)::watch(siteTags) called with : ", tags);
            this.initKtDataTable();
        },
        user: function(user) {
            if (user) {
                console.log("Component(SiteTags)::watch(user) called with :", user);
                this.initKtDataTable();
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["siteTags", "currentSite", "createAnotherTag", "tagTypeObjsByName", "user"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSiteTags", "showDeleteModal", "showImportTagsModal", "resetTagsState"]),
        onSearch() {
            if (this.siteTagsTable) {
                this.siteTagsTable.search(this.searchedValue, "serialNumber");
            }
        },
        onEditTag: function(tagId) {
            this.$router.push({ name: "editTag", params: { tagId: tagId, siteId: this.siteId } });
        },
        onEditAsset: function(assetId) {
            this.$router.push({ name: "editAsset", params: { assetId: assetId } });
        },
        registerEventsOnSiteTagsTable() {
            var self = this;
            $("#tags_datatable").on("kt-datatable--on-layout-updated", () => {
                $(".deleteTagButton")
                    .off()
                    .on("click", function() {
                        let tagId = $(this).attr("data-tagid");
                        let serialnumber = $(this).attr("data-serialnumber");
                        console.log("Component(SiteTags)::registerEventsOnSitesTable(deleteTsagButton) clicked. TagId = " + tagId);
                        self.showDeleteModal({ textContent: i18n.t("tag_deleteTagConfirmText", { serialnumber: serialnumber }), actionName: "DELETE_TAG", params: { siteId: self.siteId, tagId: tagId } });
                    });
                $(".editTagButton")
                    .off()
                    .on("click", function() {
                        let tagId = $(this).attr("data-tagid");
                        console.log("Component(SiteTags)::registerEventsOnSitesTable(editTagButton) clicked. TagId = " + tagId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue
                        self.onEditTag(tagId);
                    });
                $(".editAssetButton")
                    .off()
                    .on("click", function() {
                        let assetId = $(this).attr("data-assetid");
                        console.log("Component(SiteTags)::registerEventsOnSitesTable(editAssetButton) clicked. assetId = " + assetId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue of asset
                        self.onEditAsset(assetId);
                    });
                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");
                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
                }, 50);
            });
        },
        openImportTagsModal() {
            // Reset data in modal
            this.$refs.importTagsModal.errorDisplayed = false;
            this.$refs.importTagsModal.xlsData = null;
            this.$refs.importTagsModal.isImportButtonDisabled = true;
            this.$refs.importTagsModal.xlsErrors = null;
            this.$refs.importTagsModal.xlsFile = null;
            this.$refs.importTagsModal.isXlsFileIsEmpty = false;
            this.$refs.importTagsModal.isSheetNotFound = false;
            // Force input file empty
            $("#importTagsInputFile").val("");
            // Reset placeholder of label
            $("#importTagsInputLabel").text(i18n.t("common_chooseFile"));
            // Open modal
            this.showImportTagsModal();
        },
        exportTagsModal() {
            // Call common vueHelper function
            var data = [];
            // Creation of table header
            var header = [
                {name: "Tag Mac Address", type: "STRING"},
                {name: "Type of Tag", type: "STRING"},
                {name: "Serial Number", type: "STRING"},
                {name: "Battery Installation Date (YYYY-MM-DD)", type: "DATE"},
                {name: "Associated Asset", type: "STRING"}
            ];
            this.siteTags.forEach(tag => {
                var tagInfo = [tag.macAddress, tag.tagType.name, tag.serialNumber, tag.batteryInstallationDate];
                if (tag.asset && tag.asset.name) {
                    tagInfo.push(tag.asset.name);
                } else {
                    tagInfo.push("-");
                }
                data.push(tagInfo);
            });
            commonVueHelper.exportsDataToExcel(data, header, "tags", "tags");
        },
        initKtDataTable() {
            // Prepa data
            let data = [];
            if (this.siteTags && this.siteTags.length > 0) {
                data = this.siteTags.map((tag) => {
                    let finalTag = {...tag};
                    if (tag.tagType) {
                        if (tag.tagType.isDefault) {
                            finalTag.tagTypeFullRef = commonVueHelper.getPropFromTagType("fullRef", tag.tagType);
                        } else {
                            finalTag.tagTypeFullRef = tag.tagType.name + " (" + tag.tagType.reference + ")";
                        }
                        if (!tag.tagType.image) {
                            finalTag.tagType.image = "/assets/tags/universal.svg";
                        }
                    }
                    finalTag.formatUpdatedAt = moment(tag.updatedAt).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                    if (tag.asset) {
                        finalTag.associatedAsset = tag.asset.name;
                    } else {
                        finalTag.associatedAsset = "-";
                    }
                    return finalTag;
                });
            }

            const options = {
                data: {
                    type: "local",
                    source: commonVueHelper.preventHtmlInjection(data),
                    pageSize: 20,
                    saveState: {
                        webstorage: false
                    }
                },
                layout: commonVueHelper.getLayoutOptions(),
                translate: commonVueHelper.getTranslateOptions(),
                toolbar: commonVueHelper.getToolbarOptions(),
                sortable: !0,
                pagination: !0,
                columns: [
                    {
                        field: "serialNumber",
                        title: i18n.t("tag_serialNumberLabel"),
                        default: "-",
                        textAlign: "center",
                        overflow: "visible",
                        autoHide: false,
                        template: function(row) {
                            if (row.serialNumber) {
                                var createdAt = moment(row.createdAt);
                                var now = moment();
                                var duration = moment.duration(now.diff(createdAt));
                                var minutes = duration.asMinutes();
                                if (minutes < 15) {
                                    return '\
                                        <div class="kt-user-card-v2 word-break">\
                                            <div class="kt-user-card-v2__details openResourceLink">\
                                                <a class="editTagButton kt-link kt-user-card-v2__name" data-tagid="' + row.id + '" href="javascript:;">' + row.serialNumber + "<span class='labelNew'>" + i18n.t("common_new") + "</span></a>\
                                            </div>\
                                        </div>\
                                    ";
                                } else {
                                    return '\
                                        <div class="kt-user-card-v2 word-break">\
                                            <div class="kt-user-card-v2__details openResourceLink">\
                                                <a class="editTagButton kt-link kt-user-card-v2__name" data-tagid="' + row.id + '" href="javascript:;">' + row.serialNumber + "</a>\
                                            </div>\
                                        </div>\
                                    ";
                                }
                            } else {
                                return "-";
                            }
                        }
                    },
                    {
                        field: "tagTypeFullRef",
                        title: i18n.t("MAP_TAG_TYPE"),
                        textAlign: "left",
                        width: 250,
                        template: function(row) {
                            if (row.tagType) {
                                let tagTypeImg;
                                if (row.tagType.isDefault) {
                                    tagTypeImg = commonVueHelper.getPropFromTagType("img", row.tagType);
                                } else {
                                    tagTypeImg = row.tagType.image;
                                }
                                return (
                                    `<div class="kt-user-card-v2">
                                        <img src="` +
                                    tagTypeImg +
                                    `" class="tagsIconPreview" />
                                        <div class="kt-user-card-v2__details" style="margin-left:8px;">
                                            <span class="word-break">` +
                                    row.tagTypeFullRef +
                                    `
                                        </div>
                                    </div>`
                                );
                            }
                        }
                    },
                    {
                        field: "updatedAt",
                        title: i18n.t("common_lastUpdateDate"),
                        textAlign: "center",
                        sortable: "desc",
                        template: function(row) {
                            return row.formatUpdatedAt;
                        }
                    },
                    {
                        field: "associatedAsset",
                        title: i18n.t("tag_associatedAsset"),
                        textAlign: "center",
                        sortable: "desc",
                        overflow: "visible",
                        autoHide: false,
                        template: function(row) {
                            if (row.asset) {
                                return '\
                                    <div class="kt-user-card-v2">\
                                        <div class="kt-user-card-v2__details openResourceLink">\
                                            <a class="editAssetButton kt-link kt-user-card-v2__name" data-assetid="' + row.asset.id + '" href="javascript:;">' + row.associatedAsset + "</a>\
                                        </div>\
                                    </div>\
                                ";
                            } else {
                                return "-";
                            }
                        }
                    },
                    {
                        field: "batteryInstallationDate",
                        title: i18n.t("tag_EstimatedBatteryStatusLabel"),
                        overflow: "visible",
                        textAlign: "center",
                        autoHide: false,
                        sortable: false,
                        template: function(row) {
                            if (row.hasOwnProperty("realBatteryStatusValue") && row.realBatteryStatusValue && row.realBatteryStatusValue >= 0) {
                                if (row.realBatteryStatusValue < 30) {
                                    const valueToDisplay = row.realBatteryStatusValue <= 0 ? 0 : row.realBatteryStatusValue;
                                    return (
                                        `<div class="kt-user-card-v2 marginT4BatStatus">
                                            <div class="progress" style="width: 30px; margin: 0 auto;">
                                                <div class="progress-bar bg-danger" role="progressbar" style="width: `+ row.realBatteryStatusValue + `%" aria-valuenow="`+ row.realBatteryStatusValue + `" aria-valuemin="0" aria-valuemax="30"></div>
                                            </div>
                                        </div>
                                        <div class="batteryStatusStr">` + valueToDisplay +` %</div>`
                                    );
                                } else if (row.realBatteryStatusValue < 70 && row.realBatteryStatusValue >= 30) {
                                    return (
                                        `<div class="kt-user-card-v2 marginT4BatStatus">
                                            <div class="progress" style="width: 30px; margin: 0 auto;">
                                                <div class="progress-bar bg-warning" role="progressbar" style="width: `+ row.realBatteryStatusValue + `%" aria-valuenow="`+ row.realBatteryStatusValue + `" aria-valuemin="0" aria-valuemax="50"></div>
                                            </div>
                                        </div>
                                        <div class="batteryStatusStr">` + row.realBatteryStatusValue +` %</div>`
                                    );
                                } else {
                                    const valueToDisplay = row.realBatteryStatusValue > 100 ? 100 : row.realBatteryStatusValue;
                                    return (
                                        `<div class="kt-user-card-v2 marginT4BatStatus">
                                            <div class="progress" style="width: 30px; margin: 0 auto;">
                                                <div class="progress-bar bg-success" role="progressbar" style="width: `+ row.realBatteryStatusValue + `%" aria-valuenow="`+ row.realBatteryStatusValue + `" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="batteryStatusStr">` + valueToDisplay +` %</div>`
                                    );
                                }
                            } else {
                                if (row.tagType) {
                                    let batteryLifetime = row.tagType.batteryLifetime;
                                    let estimatedBatteryLifetimeEndDate = moment(row.batteryInstallationDate).add(batteryLifetime, "months");
                                    let estimatedBatteryLifetimeEndDateMinus6Months = moment(estimatedBatteryLifetimeEndDate).subtract(6, "months");
                                    let estimateBatteryMiddleLifetimeDate = moment(estimatedBatteryLifetimeEndDate).subtract(batteryLifetime / 2, "months");
                                    let now = moment();
                                    if (now.isAfter(estimatedBatteryLifetimeEndDate) || now.isAfter(estimatedBatteryLifetimeEndDateMinus6Months)) {
                                        return (
                                            `<div class="kt-user-card-v2 marginT4BatStatus">
                                                <div class="progress" style="width: 30px; margin: 0 auto;">
                                                    <div class="progress-bar bg-danger" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="10"></div>
                                                </div>
                                            </div>
                                            <div class="batteryStatusStr">` + i18n.t("tag_EstimatedBatteryLabel") +`<sup>*</sup></div>`
                                        );
                                    } else if (now.isAfter(estimateBatteryMiddleLifetimeDate) && now.isBefore(estimatedBatteryLifetimeEndDateMinus6Months)) {
                                        return (
                                            `<div class="kt-user-card-v2 marginT4BatStatus">
                                                <div class="progress" style="width: 30px; margin: 0 auto;">
                                                    <div class="progress-bar bg-warning" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="50"></div>
                                                </div>
                                            </div>
                                            <div class="batteryStatusStr">` + i18n.t("tag_EstimatedBatteryLabel") +`<sup>*</sup></div>`
                                        );
                                    } else {
                                        return (
                                            `<div class="kt-user-card-v2 marginT4BatStatus">
                                                <div class="progress" style="width: 30px; margin: 0 auto;">
                                                    <div class="progress-bar bg-success" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div class="batteryStatusStr">` + i18n.t("tag_EstimatedBatteryLabel") +`<sup>*</sup></div>`
                                        );
                                    }
                                } else {
                                    return i18n.t("common_unknown");
                                }
                            }
                        }
                    },
                    {
                        field: "Actions",
                        title: i18n.t("common_actions"),
                        sortable: false,
                        width: 110,
                        overflow: "visible",
                        autoHide: false,
                        textAlign: "center",
                        template: function(row) {
                            return (
                                `
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                i18n.t("common_edit") +
                                `" type="button" data-tagid="` +
                                row.id +
                                `" data-macaddress="` +
                                row.macAddress +
                                `" class="btn btn-outline-brand btn-sm btn-icon editTagButton">
                                        <i class="la la-pencil"></i>
                                    </button>
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                i18n.t("common_delete") +
                                `" type="button" data-tagid="` +
                                row.id +
                                `" data-serialnumber="` +
                                row.serialNumber +
                                `" class="btn btn-outline-danger btn-sm btn-icon deleteTagButton">
                                        <i class="la la-trash"></i>
                                    </button>
                                `
                            );
                        }
                    }
                ]
            };

            // If site table has been already build, destroy the table before creating a fresh instance !
            if (this.siteTagsTable) {
                $("#tags_datatable").KTDatatable().destroy();
                // After destroy set to null
                this.siteTagsTable = null;
            }

            // According to source list length, show datatable or empty list icon+text
            if (options.data.source && options.data.source.length > 0) {
                // There is data => Hide empty list icon+text
                this.isEmptyList = false;
                // Now, we are ready to build the table
                this.siteTagsTable = $("#tags_datatable").KTDatatable(options);
                // Once table is build, register click events on buttons inside the table
                this.registerEventsOnSiteTagsTable();
                this.isBatteryStatusHelpDisplayed = true;
            } else {
                // There is no data => Show empty list icon+text
                this.isEmptyList = true;
                this.isBatteryStatusHelpDisplayed = false;
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle,
        "app-importtagsfromXLSmodal": ImportTagsModal
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
